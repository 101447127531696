export default {
  'Request a customization': 'Request a customization',
  '*mandatory field': '*mandatory field',
  'APPLY SETTINGS': 'APPLY SETTINGS',
  'About us': 'About us',
  Acquista: 'Acquista',
  'Add To Favourites': 'Add To Favourites',
  'Add new address': 'Add new address',
  'Add the address': 'Add the address',
  'Add to Wishlist': 'Add to Wishlist',
  'Add to cart': 'Add to cart',
  'Add to compare': 'Add to compare',
  'Add to favourites': 'Add to favourites',
  'Additional Information': 'Additional Information',
  'Addresses details': 'Addresses details',
  'All Orders': 'All Orders',
  'All right reserved': 'All right reserved',
  'Allow order notifications': 'Allow order notifications',
  Amount: 'Amount',
  Annulla: 'Annulla',
  Apply: 'Apply',
  'Apply filters': 'Apply filters',
  'Art Déco': 'Art Déco',
  'Attention!': 'Attention!',
  Available: 'Available',
  'Back to home': 'Back to home',
  'classic workmanship': 'classic workmanship',
  blowing: 'blowing',
  plate: 'plate',
  'caged glass': 'caged glass',
  mirrors: 'mirrors',
  'Back to home page': 'Back to home page',
  'Back to homepage': 'Back to homepage',
  Billing: 'Billing',
  'Billing address': 'Billing address',
  Brand: 'Brand',
  'thank you for your purchase': 'thank you for your purchase',
  'ORDER NUMBER:': 'ORDER NUMBER:',
  'You have placed your order correctly. You can check the status of your order using our delivery status feature. You will receive an order confirmation email with the details of your order and a link to track its status.':
    'You have placed your order correctly. You can check the status of your order using our delivery status feature. You will receive an order confirmation email with the details of your order and a link to track its status.',
  'You can access your account using the previously defined email and password. On your account you can edit your profile data, transactions, change your newsletter subscription.':
    'You can access your account using the previously defined email and password. On your account you can edit your profile data, transactions, change your newsletter subscription.',
  'Browse our unique collection': 'Browse our unique collection',
  'By clicking \'subscribe\', I agree that my information can be used for marketing segmentation':
    'By clicking \'subscribe\', I agree that my information can be used for marketing segmentation',
  Cancel: 'Cancel',
  Carrello: 'Carrello',
  Cart: 'Cart',
  Categories: 'Categories',
  Cba: 'Cba',
  'Certificate of authenticity': 'Certificate of authenticity',
  'You are already subscribed to the newsletter. for support contact us at info@sognidicristallo.it':
    'You are already subscribed to the newsletter. for support contact us at info@sognidicristallo.it',
  'Sorry, we couldn\'t subscribe you to our newsletter. Please contact us at info@sognidicristallo.it':
    'Sorry, we couldn\'t subscribe you to our newsletter. Please contact us at info@sognidicristallo.it',
  'Your subscription was successfull!': 'Your subscription was successfull!',
  Change: 'Change',
  'Change password your account': 'Change password your account',
  'Changed your mind?': 'Changed your mind?',
  City: 'City',
  Classic: 'Classic',
  'Clear all': 'Clear all',
  Color: 'Color',
  'Colore Montatura': 'Colore Montatura',
  'Come visit': 'Come visit',
  'Commercial information': 'Commercial information',
  'Compatible electric wiring': 'Compatible electric wiring',
  'Confirmation request has been sent': 'Confirmation request has been sent',
  'Contact details updated': 'Contact details updated',
  'Contact us': 'Contact us',
  'Contact us for your project': 'Contact us for your project',
  Contacts: 'Contacts',
  'Continue to billing': 'Continue to billing',
  'Continue to payment': 'Continue to payment',
  'Continue to shipping': 'Continue to shipping',
  'Cookies Policy': 'Cookies Policy',
  'Cookies policy': 'Cookies policy',
  'Copy address data from shipping': 'Copy address data from shipping',
  'Corriere espresso assicurato': 'Corriere espresso assicurato',
  Country: 'Country',
  'Create an account': 'Create an account',
  'Create an account on the store': 'Create an account on the store',
  'Current Password': 'Current Password',
  'Customer Reviews': 'Customer Reviews',
  'Customer service': 'Customer service',
  'DESIGN BY': 'DESIGN BY',
  DIMENSIONS: 'DIMENSIONS',
  Date: 'Date',
  'Date of birth': 'Date of birth',
  'Default Billing Address': 'Default Billing Address',
  'Default Combination': 'Default Combination',
  'Default Shipping Address': 'Default Shipping Address',
  Delete: 'Delete',
  Departments: 'Departments',
  Description: 'Description',
  'Description product': 'Description product',
  'Designed by': 'Designed by',
  Details: 'Details',
  'Details and status orders': 'Details and status orders',
  Diameter: 'Diameter',
  Width: 'Width',
  Depth: 'Depth',
  Diametro: 'Diametro',
  Dimensioni: 'Dimensioni',
  Discount: 'Discount',
  'Discover our wool': 'Discover our wool',
  'Discover ours': 'Discover ours',
  'Discover the new winter sweaters': 'Discover the new winter sweaters',
  'Do you need help?': 'Do you need help?',
  'Do you want to be notified if the sizes will be available again?': 'Do you want to be notified if the sizes will be available again?',
  'Do you want to contact us?': 'Do you want to contact us?',
  Done: 'Done',
  Download: 'Download',
  'Download all': 'Download all',
  'Easy shipping': 'Easy shipping',
  Edit: 'Edit',
  'Email address': 'Email address',
  Empty: 'Empty',
  'Enjoy your free account': 'Enjoy your free account',
  'Enter promo code': 'Enter promo code',
  'Enter your E-mail here': 'Enter your E-mail here',
  'Error: You reached the maximum saleable quantity': 'Error: You reached the maximum saleable quantity',
  FEATURES: 'FEATURES',
  'FIND YOUR STYLE': 'FIND YOUR STYLE',
  'Fatto con amore': 'Fatto con amore',
  Feedback: 'Feedback',
  'On this page you can update your personal data at any time.': 'On this page you can update your personal data at any time.',
  'Fill out the form below!': 'Fill out the form below!',
  Filters: 'Filters',
  'Find out more': 'Find out more',
  'Find out shipping costs': 'Find out shipping costs',
  Floral: 'Floral',
  'Follow us and stay updated': 'Follow us and stay updated',
  'Forgot Password': 'Forgot Password',
  'Forgot Password Modal Email': 'Forgot Password Modal Email',
  'Forgotten password?': 'Forgotten password?',
  'Free shipping': 'Free shipping',
  'Get inspired': 'Get inspired',
  'Get inspired We think you might love these': 'Get inspired We think you might love these',
  'Glass Color': 'Glass Color',
  'Go back': 'Go back',
  'Go back shopping': 'Go back shopping',
  'Go back to shop': 'Go back to shop',
  'Go to cart': 'Go to cart',
  'Go to checkout': 'Go to checkout',
  Guarantee: 'Guarantee',
  Help: 'Help',
  'Help & FAQs': 'Help & FAQs',
  'Here is a list of related garments': 'Here is a list of related garments',
  Home: 'Home',
  'House/Apartment number': 'House/Apartment number',
  'I agree to': 'I agree to',
  'I agree with the handling of my data by this website for profiling activities, for example the creation of personalized contents or offers':
    'I agree with the handling of my data by this website for profiling activities, for example the creation of personalized contents or offers',
  'I agree with the handling of my data by this website in order to receive commercial or promotional communications through a newsletter':
    'I agree with the handling of my data by this website in order to receive commercial or promotional communications through a newsletter',
  'I confirm subscription': 'I confirm subscription',
  'I have read and agree to the': 'I have read and agree to the',
  'In our stores, as well as online, we welcome women from different generations. From mothers to their young daughters':
    'In our stores, as well as online, we welcome women from different generations. From mothers to their young daughters',
  'Info after order': 'Info after order',
  Instruction1: 'Instruction1',
  Instruction2: 'Instruction2',
  Instruction3: 'Instruction3',
  'Insured express courier': 'Insured express courier',
  'Invalid email': 'Invalid email',
  'It carefully packaged with a personal touch': 'It carefully packaged with a personal touch',
  'It looks like you haven"t added any items to your cart yet': 'It looks like you haven"t added any items to your cart yet',
  'It looks like you haven’t added any items to your  wishlist yet': 'It looks like you haven’t added any items to your  wishlist yet',
  'It looks like you haven’t added any items to your cart yet': 'It looks like you haven’t added any items to your cart yet',
  'It looks like you haven’t added any items to your wishlist yet': 'It looks like you haven’t added any items to your wishlist yet',
  'It was not possible to request a new password, please check the entered email address':
    'It was not possible to request a new password, please check the entered email address',
  'Italian size': 'Italian size',
  Item: 'Item',
  Items: 'Items',
  'Keep your addresses and contact details up to date': 'Keep your addresses and contact details up to date',
  'Key years of our company which has moved from a production for third parties to the production and direct sale of our brand':
    'Key years of our company which has moved from a production for third parties to the production and direct sale of our brand',
  Kidswear: 'Kidswear',
  'La nostra storia': 'La nostra storia',
  'Laboratory born in the province of Vicenza, starting to work for big fashion brands, producing quality raincoats and shrugs':
    'Laboratory born in the province of Vicenza, starting to work for big fashion brands, producing quality raincoats and shrugs',
  Language: 'Language',
  'Last Name': 'Last Name',
  'Last name': 'Last name',
  'Latest Stories': 'Latest Stories',
  'Latest View': 'Latest View',
  'Led Compatible': 'Led Compatible',
  'Let yourself be guided by our advice': 'Let yourself be guided by our advice',
  'Let’s start now - we’ll help you': 'Let’s start now - we’ll help you',
  'Let’s start now – we’ll help you': 'Let’s start now – we’ll help you',
  'Log into your account': 'Log into your account',
  Login: 'Login',
  'Login on the store': 'Login on the store',
  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in':
    'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in',
  'Looks like you haven’t added any items to the cart yet. Start shopping to fill it in':
    'Looks like you haven’t added any items to the cart yet. Start shopping to fill it in',
  'Lunghezza catena': 'Lunghezza catena',
  Luxury: 'Luxury',
  'Made with love in Italy': 'Made with love in Italy',
  'Make an order': 'Make an order',
  Man: 'Man',
  'Manage all the shipping addresses you want. This way you won\'t have to enter the shipping address manually with each order':
    'Manage all the shipping addresses you want. This way you won\'t have to enter the shipping address manually with each order',
  'Manage billing addresses': 'Manage billing addresses',
  'Manage shipping addresses': 'Manage shipping addresses',
  'Match it with': 'Match it with',
  'Men fashion': 'Men fashion',
  Message: 'Message',
  'Mid-century': 'Mid-century',
  Modern: 'Modern',
  Monday: 'Monday',
  Multicolor: 'Multicolor',
  'Must be longer than than {0} characters': 'Must be longer than than {0} characters',
  'Must be shorter than {0} characters': 'Must be shorter than {0} characters',
  'My Account': 'My Account',
  'My Cart': 'My Cart',
  'My newsletter': 'My newsletter',
  'My orders': 'My orders',
  'My profile': 'My profile',
  Name: 'Name',
  'New Password': 'New Password',
  'No account': 'No account',
  'Notify me if back in stock': 'Notify me if back in stock',
  'Number of lights': 'Number of lights',
  'On Hold': 'On Hold',
  'On average, delivery in Italy takes place 24-48 hours after the shipment, whilst it may take 2-6 business days for Europe and other countries':
    'On average, delivery in Italy takes place 24-48 hours after the shipment, whilst it may take 2-6 business days for Europe and other countries',
  'Order ID': 'Order ID',
  'Order No': 'Order No',
  'Order details': 'Order details',
  'Order history': 'Order history',
  'Order review': 'Order review',
  'Order summary': 'Order summary',
  'Other products you might like': 'Other products you might like',
  'Our garments are made with precious raw materials and carefully chosen paying attention to their origin in compliance with the rules on sustainability':
    'Our garments are made with precious raw materials and carefully chosen paying attention to their origin in compliance with the rules on sustainability',
  'Our product is the result of a lot of love for our job and we believe in the true Made in Italy, understood as the packaging of clothing made with precious raw materials: the choice of the fabrics we use is made taking into account the origin of the same, with a preference for materials whose printing and finishing are made in Italy in compliance with sustainability':
    'Our product is the result of a lot of love for our job and we believe in the true Made in Italy, understood as the packaging of clothing made with precious raw materials: the choice of the fabrics we use is made taking into account the origin of the same, with a preference for materials whose printing and finishing are made in Italy in compliance with sustainability',
  regulations: 'regulations',
  'Our recommendations': 'Our recommendations',
  'Our story': 'Our story',
  'Out of stock': 'Out of stock',
  'PRODUCT DESCRIPTION': 'PRODUCT DESCRIPTION',
  'PRODUCT INFO': 'PRODUCT INFO',
  Password: 'Password',
  'Password Changed': 'Password Changed',
  'Password change': 'Password change',
  'Passwords don\'t match': 'Passwords don\'t match',
  'Pay for order': 'Pay for order',
  Payment: 'Payment',
  'Payment & Delivery': 'Payment & Delivery',
  'Payment date': 'Payment date',
  'Payment method': 'Payment method',
  'Payment methods': 'Payment methods',
  'Personal Details': 'Personal Details',
  'Personal data': 'Personal data',
  'Personal details': 'Personal details',
  Phone: 'Phone',
  'Phone Number': 'Phone Number',
  'Phone number': 'Phone number',
  'Please enter a valid email address': 'Please enter a valid email address',
  'Please select a country first': 'Please select a country first',
  'Please type your current password to change your email address': 'Please type your current password to change your email address',
  Price: 'Price',
  'Primary contacts for any questions': 'Primary contacts for any questions',
  Privacy: 'Privacy',
  'Privacy Policy': 'Privacy Policy',
  'Proceed to purchase': 'Proceed to purchase',
  Product: 'Product',
  'Product description': 'Product description',
  'Product options': 'Product options',
  'Product suggestions': 'Product suggestions',
  Products: 'Products',
  'Products found': 'Products found',
  'Purchase terms': 'Purchase terms',
  'Quality in the details': 'Quality in the details',
  Quantity: 'Quantity',
  'Quantity:': 'Quantity:',
  'Re-Password': 'Re-Password',
  'Read all reviews': 'Read all reviews',
  'Read and understand': 'Read and understand',
  'Read reviews': 'Read reviews',
  Register: 'Register',
  'Register today': 'Register today',
  Remove: 'Remove',
  'Remove from Wishlist': 'Remove from Wishlist',
  'Repeat Password': 'Repeat Password',
  'Required field': 'Required field',
  'Reset Password': 'Reset Password',
  'Reset filters': 'Reset filters',
  'Reset your filters': 'Reset your filters',
  'Rest assured, we offer free returns within 30 days': 'Rest assured, we offer free returns within 30 days',
  'Review my order': 'Review my order',
  'SHOP NOW': 'SHOP NOW',
  Safety: 'Safety',
  'Same as shipping address': 'Same as shipping address',
  'Save Password': 'Save Password',
  'Save changes': 'Save changes',
  'Save for later': 'Save for later',
  Search: 'Search',
  'Search Results': 'Search Results',
  'Search for items': 'Search for items',
  'Search results': 'Search results',
  'Sections that interest you': 'Sections that interest you',
  'Secure payments': 'Secure payments',
  'See all results': 'See all results',
  'Sei sicuro di voler rimuovere questo articolo dal carrello?': 'Sei sicuro di voler rimuovere questo articolo dal carrello?',
  'Select payment method': 'Select payment method',
  'Select product variant': 'Select product variant',
  'Select shipping method': 'Select shipping method',
  'Send Message': 'Send Message',
  'Send my feedback': 'Send my feedback',
  Services: 'Services',
  'Set as default billing': 'Set as default billing',
  'Set as default shipping': 'Set as default shipping',
  'Set up newsletter': 'Set up newsletter',
  Share: 'Share',
  'Share your look': 'Share your look',
  'Shipments are made by UPS courier and are normally dispatched within 48 hours. Only in exceptional cases (for example during the sales period) it may take 3-4 business days to prepare':
    'Shipments are made by UPS courier and are normally dispatched within 48 hours. Only in exceptional cases (for example during the sales period) it may take 3-4 business days to prepare',
  'the shipment': 'the shipment',
  Shipping: 'Shipping',
  'Shipping address': 'Shipping address',
  'Shipping and returns': 'Shipping and returns',
  'Shipping details': 'Shipping details',
  'Shipping method': 'Shipping method',
  'Shipping status can be verified at any time by using the tracking number provided via mail':
    'Shipping status can be verified at any time by using the tracking number provided via mail',
  Shops: 'Shops',
  'Show on page': 'Show on page',
  Si: 'Si',
  'Siamo una piccola azienda di famiglia: abbiamo forti legami con il tessuto produttivo del nostro paese ma ispirazioni molto internazionali':
    'Siamo una piccola azienda di famiglia: abbiamo forti legami con il tessuto produttivo del nostro paese ma ispirazioni molto internazionali',
  'Sign Up for Newsletter': 'Sign Up for Newsletter',
  'Sign in': 'Sign in',
  'Sign up for our newsletter': 'Sign up for our newsletter',
  Signup: 'Signup',
  Size: 'Size',
  'Size guide': 'Size guide',
  'Size specifications': 'Size specifications',
  Sizes: 'Sizes',
  Social: 'Social',
  'Sogni Di Cristallo is a leader in indoor lighting made in the province of Venice, Italy, according to the ancient technique of Murano glass in our furnaces':
    'Sogni Di Cristallo is a leader in indoor lighting made in the province of Venice, Italy, according to the ancient technique of Murano glass in our furnaces',
  'Something went wrong during form submission. Please try again later': 'Something went wrong during form submission. Please try again later',
  'Sort by': 'Sort by',
  'Sort by relevance': 'Sort by relevance',
  'Sort: Default': 'Sort: Default',
  'Sort: Name A-Z': 'Sort: Name A-Z',
  'Sort: Name Z-A': 'Sort: Name Z-A',
  'Sort: New arrivals': 'Sort: New arrivals',
  'Sort: Price from high to low': 'Sort: Price from high to low',
  'Sort: Price from low to high': 'Sort: Price from low to high',
  'Start shopping': 'Start shopping',
  'State/Province': 'State/Province',
  Status: 'Status',
  'Street name': 'Street name',
  Submit: 'Submit',
  Subscribe: 'Subscribe',
  'Subscribe to newsletter': 'Subscribe to newsletter',
  'Subscribe to the newsletter': 'Subscribe to the newsletter',
  Subtotal: 'Subtotal',
  'Successful placed order': 'Successful placed order',
  'Suggested for you': 'Suggested for you',
  Summary: 'Summary',
  Sunday: 'Sunday',
  'Technical specifications': 'Technical specifications',
  'Terms and conditions': 'Terms and conditions',
  'Thank You Inbox': 'Thank You Inbox',
  'Thank you for your order!': 'Thank you for your order!',
  'The field should have at least 2 characters': 'The field should have at least 2 characters',
  'The field should have at least {length} characters': 'The field should have at least {length} characters',
  'The novelty of the moment!': 'The novelty of the moment!',
  'The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)':
    'The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)',
  'The size and color must be selected before adding a product to the cart!':
    'The size and color must be selected before adding a product to the cart!',
  'The user account data was successfully updated!': 'The user account data was successfully updated!',
  'The workshop moves to a colonial house in the center of the town. Current headquarters of our company in San Bonifacio':
    'The workshop moves to a colonial house in the center of the town. Current headquarters of our company in San Bonifacio',
  'This feature is not implemented yet! Please take a look at': 'This feature is not implemented yet! Please take a look at',
  'This field is required': 'This field is required',
  'Torna allo shopping': 'Torna allo shopping',
  Total: 'Total',
  'Total items': 'Total items',
  'Total price': 'Total price',
  'Tuesday to Saturday': 'Tuesday to Saturday',
  Type: 'Type',
  'Update password': 'Update password',
  'Update personal data': 'Update personal data',
  'Update the address': 'Update the address',
  'Use this address as my default one': 'Use this address as my default one',
  'Use your personal data': 'Use your personal data',
  'Useful informations': 'Useful informations',
  'User Account': 'User Account',
  'Usually arrives in 5-13 business days. A shipping timeline specific to your destination can be viewed in Checkout':
    'Usually arrives in 5-13 business days. A shipping timeline specific to your destination can be viewed in Checkout',
  View: 'View',
  'View details': 'View details',
  'View video': 'View video',
  'We are here to answer your questions': 'We are here to answer your questions',
  'We think you might love these': 'We think you might love these',
  'Where do you want to purchase?': 'Where do you want to purchase?',
  'Who we are': 'Who we are',
  Woman: 'Woman',
  'Women fashion': 'Women fashion',
  'Worldwide delivery in 2/7 working days': 'Worldwide delivery in 2/7 working days',
  'Would you like to discover all about payments and returns? Have a look here. (\'here\' linkato a pagina customer care)':
    'Would you like to discover all about payments and returns? Have a look here. (\'here\' linkato a pagina customer care)',
  'Wrong user credentials': 'Wrong user credentials',
  Yes: 'Yes',
  'You are currently Shopping in': 'You are currently Shopping in',
  'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, of transactions, edit subscription to newsletter':
    'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, of transactions, edit subscription to newsletter',
  'You can unsubscribe at any time': 'You can unsubscribe at any time',
  'You currently have no orders': 'You currently have no orders',
  'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order':
    'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order',
  'and a link to track its progress': 'and a link to track its progress',
  'You haven’t searched for items yet': 'You haven’t searched for items yet',
  'Your Account': 'Your Account',
  'Your Cart': 'Your Cart',
  'Your Email': 'Your Email',
  'Your Email*': 'Your Email*',
  'Your Purchase': 'Your Purchase',
  'Your bag is empty': 'Your bag is empty',
  'Your cart is empty': 'Your cart is empty',
  'Your current email address is': 'Your current email address is',
  'Your e-mail': 'Your e-mail',
  'Your email': 'Your email',
  'Your shopping cart is empty': 'Your shopping cart is empty',
  'Your wishlist is empty': 'Your wishlist is empty',
  'You’ll receive dispatch confirmation and an arrival date': 'You’ll receive dispatch confirmation and an arrival date',
  'Zip-code': 'Zip-code',
  colors: 'colors',
  estimatedDelivery: 'Estimated delivery between {0} and {1}',
  'for our Roadmap!': 'for our Roadmap!',
  forgotPasswordConfirmation: 'forgotPasswordConfirmation',
  hide: 'hide',
  'in Italia': 'in Italia',
  'login in to your account': 'login in to your account',
  or: 'or',
  'or fill the details below': 'or fill the details below',
  'password repeat': 'password repeat',
  'phone : +390414762538': 'phone : +390414762538',
  'proceed to checkout': 'proceed to checkout',
  promo: 'promo',
  shipping: 'shipping',
  'shop now': 'shop now',
  'show more': 'show more',
  subscribeToNewsletterModalContent: 'subscribeToNewsletterModalContent',
  subtotal: 'subtotal',
  'successfully removed from your cart': 'successfully removed from your cart',
  'view in cart': 'view in cart',
  'you discover': 'you discover',
  'Featured on': 'Featured on',
  REMOVE: 'REMOVE',
  Close: 'Close',
  'Your Cart is empty': 'Your Cart is empty',
  'Reset password': 'Reset password',
  '30035 Mirano': '30035 Mirano',
  About: 'About',
  'All products loaded': 'All products loaded',
  'Already returned': 'Already returned',
  'An error occurred': 'An error occurred',
  'An error occurred during 3D Secure authentication': 'An error occurred during 3D Secure authentication',
  'Available in': 'Available in',
  CVV: 'CVV',
  'Card Number': 'Card Number',
  Checkout: 'Checkout',
  'Come back': 'Come back',
  Comments: 'Comments',
  Company: 'Company',
  Contact: 'Contact',
  'Create Account': 'Create Account',
  Customer: 'Customer',
  Customizable: 'Customizable',
  Destination: 'Destination',
  'Shipping country': 'Shipping country',
  Downloads: 'Downloads',
  Email: 'Email',
  'Enter gift card code': 'Enter gift card code',
  'Error on shipping method': 'Error on shipping method',
  'Error while adding product to cart. Please try later': 'Error while adding product to cart. Please try later',
  'An error occurred. Please try again later': 'An error occurred. Please try again later',
  'Expiration Date': 'Expiration Date',
  'Filter / Order by': 'Filter / Order by',
  'Filters / Order by': 'Filters / Order by',
  'Gift card': 'Gift card',
  Height: 'Height',
  Homepage: 'Homepage',
  'I have read and agree to the ': 'I have read and agree to the ',
  'Insert promo code here': 'Insert promo code here',
  'Insert code here': 'Insert code here',
  Length: 'Length',
  Lights: 'Lights',
  'Load More': 'Load More',
  'Load more products': 'Load more products',
  'Log in': 'Log in',
  Logout: 'Logout',
  'Make the order': 'Make the order',
  'Manage all the shipping addresses you want. This way you won': 'Manage all the shipping addresses you want. This way you won',
  'Manage my addresses': 'Manage my addresses',
  'Meet the glass masters': 'Meet the glass masters',
  Mount: 'Mount',
  'My addresses': 'My addresses',
  'My billing and shipping address are the same': 'My billing and shipping address are the same',
  Newsletter: 'Newsletter',
  Next: 'Next',
  'No products were found': 'No products were found',
  'Office/Showroom': 'Office/Showroom',
  Order: 'Order',
  'Order Summary': 'Order Summary',
  'Order by': 'Order by',
  'Our staff is available to you at the following contacts': 'Our staff is available to you at the following contacts',
  'P.I: 04513400277': 'P.I: 04513400277',
  'PROMO CODE': 'PROMO CODE',
  'Paid with gift card': 'Paid with gift card',
  'Payment canceled': 'Payment canceled',
  'Payment currently unavailable': 'Payment currently unavailable',
  Prev: 'Prev',
  'Privacy Policy statement': 'Privacy Policy statement',
  Profile: 'Profile',
  'Read all articles': 'Read all articles',
  Reason: 'Reason',
  Reload: 'Reload',
  Reorder: 'Reorder',
  'Repeat Email': 'Repeat Email',
  'Request a quote': 'Request a quote',
  'Request sent': 'Request sent',
  Required: 'Required',
  'Reset all': 'Reset all',
  'Reset password ': 'Reset password ',
  'Return information': 'Return information',
  'Return order': 'Return order',
  'Return request for order': 'Return request for order',
  'Save address': 'Save address',
  'See results': 'See results',
  'Select country and language': 'Select country and language',
  'Select your Country': 'Select your Country',
  'Send request': 'Send request',
  'Street Name': 'Street Name',
  Subject: 'Subject',
  'Subscription Error': 'Subscription Error',
  'Subscription Success': 'Subscription Success',
  'The page you were looking for cannot be found': 'The page you were looking for cannot be found',
  'Track your order': 'Track your order',
  'Update my data': 'Update my data',
  'Useful Informations': 'Useful Informations',
  VENICE: 'VENICE',
  'View our collection': 'View our collection',
  Wishlist: 'Wishlist',
  'You don': 'You don',
  'Your cart is outdated and must be reloaded': 'Your cart is outdated and must be reloaded',
  'added to cart': 'added to cart',
  'and the': 'and the',
  'create a new cart': 'create a new cart',
  from: 'from',
  'from ': 'from ',
  'item in cart': 'item in cart',
  'items in cart': 'items in cart',
  of: 'of',
  'on demand': 'on demand',
  remove: 'remove',
  total: 'total',
  'It looks like you haven\'t added any items to your cart yet ': 'It looks like you haven\'t added any items to your cart yet ',
  'First Name': 'First Name',
  'First name': 'First name',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  Novembre: 'Novembre',
  December: 'December',
  'Write something to search': 'Write something to search',
  'I agree with the handling of my data by this website for profiling activities, for example the creation of personalized contents or offers.':
    'I agree with the handling of my data by this website for profiling activities, for example the creation of personalized contents or offers.',
  'The passwords must match': 'The passwords must match',
  'Your privacy is important! Find out how we protect your personal data in our ':
    'Your privacy is important! Find out how we protect your personal data in our ',
  'Current Password*': 'Current Password*',
  'New Password*': 'New Password*',
  'Repeat Password*': 'Repeat Password*',
  '* Mandatory field': '* Mandatory field',
  'It was not possible to request a new password, please check the entered email address.':
    'It was not possible to request a new password, please check the entered email address.',
  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.':
    'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.',
  'Keep your addresses and contact details up to date.': 'Keep your addresses and contact details up to date.',
  'Manage all the shipping addresses you want. This way you won\'t have to enter the shipping address manually with each order.':
    'Manage all the shipping addresses you want. This way you won\'t have to enter the shipping address manually with each order.',
  'Please type your current password to change your email address.': 'Please type your current password to change your email address.',
  'In our stores, as well as online, we welcome women from different generations. From mothers to their young daughters.':
    'In our stores, as well as online, we welcome women from different generations. From mothers to their young daughters.',
  'Siamo una piccola azienda di famiglia: abbiamo forti legami con il tessuto produttivo del nostro paese ma ispirazioni molto internazionali.':
    'We are a small family business: we have strong ties with the productive fabric of our country but very international inspirations.',
  'in Italia.': 'in Italy.',
  'Laboratory born in the province of Vicenza, starting to work for big fashion brands, producing quality raincoats and shrugs.':
    'Laboratory born in the province of Vicenza, starting to work for big fashion brands, producing quality raincoats and shrugs.',
  'The workshop moves to a colonial house in the center of the town. Current headquarters of our company in San Bonifacio.':
    'The workshop moves to a colonial house in the center of the town. Current headquarters of our company in San Bonifacio.',
  'Key years of our company which has moved from a production for third parties to the production and direct sale of our brand.':
    'Key years of our company which has moved from a production for third parties to the production and direct sale of our brand.',
  'Our product is the result of a lot of love for our job and we believe in the true Made in Italy, understood as the packaging of clothing made with precious raw materials: the choice of the fabrics we use is made taking into account the origin of the same, with a preference for materials whose printing and finishing are made in Italy in compliance with sustainability regulations.':
    'Our product is the result of a lot of love for our job and we believe in the true Made in Italy, understood as the packaging of clothing made with precious raw materials: the choice of the fabrics we use is made taking into account the origin of the same, with a preference for materials whose printing and finishing are made in Italy in compliance with sustainability regulations.',
  'Worldwide delivery in 2/7 working days.': 'Worldwide delivery in 2/7 working days.',
  'Made in Italy': 'Made in Italy',
  'Genuine handmade glass': 'Genuine handmade glass',
  'Super Safe Packaging': 'Super Safe Packaging',
  'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.':
    'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.',
  'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, of transactions, edit subscription to newsletter.':
    'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, of transactions, edit subscription to newsletter.',
  'Our garments are made with precious raw materials and carefully chosen paying attention to their origin in compliance with the rules on sustainability.':
    'Our garments are made with precious raw materials and carefully chosen paying attention to their origin in compliance with the rules on sustainability.',
  'Please enter a valid email address.': 'Please enter a valid email address.',
  'Use this address as my default one.': 'Use this address as my default one.',
  'Usually arrives in 5-13 business days. A shipping timeline specific to your destination can be viewed in Checkout.':
    'Usually arrives in 5-13 business days. A shipping timeline specific to your destination can be viewed in Checkout.',
  'Looks like you haven’t added any items to the cart yet. Start shopping to fill it in.':
    'Looks like you haven’t added any items to the cart yet. Start shopping to fill it in.',
  'Order No.': 'Order No.',
  'Shipments are made by UPS courier and are normally dispatched within 48 hours. Only in exceptional cases (for example during the sales period) it may take 3-4 business days to prepare the shipment.':
    'Shipments are made by UPS courier and are normally dispatched within 48 hours. Only in exceptional cases (for example during the sales period) it may take 3-4 business days to prepare the shipment.',
  'On average, delivery in Italy takes place 24-48 hours after the shipment, whilst it may take 2-6 business days for Europe and other countries.':
    'On average, delivery in Italy takes place 24-48 hours after the shipment, whilst it may take 2-6 business days for Europe and other countries.',
  'Shipping status can be verified at any time by using the tracking number provided via mail.':
    'Shipping status can be verified at any time by using the tracking number provided via mail.',
  'Would you like to discover all about payments and returns? Have a look here. ("here" linkato a pagina customer care)':
    'Would you like to discover all about payments and returns? Have a look here. ("here" linkato a pagina customer care)',
  'By clicking “subscribe”, I agree that my information can be used for marketing segmentation.':
    'By clicking “subscribe”, I agree that my information can be used for marketing segmentation.',
  'Confirmation request has been sent.': 'Confirmation request has been sent.',
  'Contact us for your project.': 'Contact us for your project.',
  'We are here to answer your questions.': 'We are here to answer your questions.',
  'Sogni Di Cristallo is a leader in indoor lighting made in the province of Venice, Italy, according to the ancient technique of Murano glass in our furnaces.':
    'Sogni Di Cristallo is a leader in indoor lighting made in the province of Venice, Italy, according to the ancient technique of Murano glass in our furnaces.',
  'It looks like you haven\'t added any items to your cart yet.': 'It looks like you haven\'t added any items to your cart yet.',
  'It looks like you haven’t added any items to your  wishlist yet.': 'It looks like you haven’t added any items to your  wishlist yet.',
  'It looks like you haven\'t added any items to your cart yet': 'It looks like you haven\'t added any items to your cart yet',
  'Technical Sheet': 'Technical Sheet',
  '360° Delivery': '360° Delivery',
  'Rapid turnaround times': 'Rapid turnaround times',
  Certifications: 'Certifications',
  'Problem solving': 'Problem solving',
  'Fast delivery': 'Fast delivery',
  'We take care of the production, delivery, and installation of the products on site.':
    'We take care of the production, delivery, and installation of the products on site.',
  'We make sure that all projects are always completed and shipped on time.':
    'We make sure that all projects are always completed and shipped on time.',
  'We keep current all CE, UL, SASO certifications. Also available for completely customized products.':
    'We keep current all CE, UL, SASO certifications. Also available for completely customized products.',
  'B2B web area where you can find all the products made during past collaborations.':
    'B2B web area where you can find all the products made during past collaborations.',
  'The London office has monthly meetings dedicated to each project.': 'The London office has monthly meetings dedicated to each project.',
  'We ship all over the world both by sea and by air, taking care of the logistics and customs organization thanks to our specialized team.':
    'We ship all over the world both by sea and by air, taking care of the logistics and customs organization thanks to our specialized team.',
  'Master Glassmaker': 'Master Glassmaker',
  'view all': 'View All',
  'Show me more products like this!': 'Show me more products like this!',
  'Download the technical sheet': 'Download the technical sheet',
  'request a price quote': 'request a price quote',
  'Your request was submitted successfully! We will get back to you as soon as possible.':
    'Your request was submitted successfully! We will get back to you as soon as possible.',
  'An error occurred while sending your request, if you are uploading a file it is possible that its size is too large':
    'An error occurred while sending your request, if you are uploading a file it is possible that its size is too large',
  'The size, color and finishes of this product can be customized according to your needs. Tell us what you would like and our team will get back to you within hours.':
    'The size, color and finishes of this product can be customized according to your needs. Tell us what you would like and our team will get back to you within hours.',
  'This product is not available': 'This product is not available',
  'you have reached the maximum quantity available due to stock limitation':
    'you have reached the maximum quantity available due to stock limitation',
  'You seem to be connected from ': 'You seem to be connected from ',
  'Do you wish to switch country?': 'Do you wish to switch country?',
  'Your password must include at least one lowercase character, one uppercase character and one digit.':
    'Your password must include at least one lowercase character, one uppercase character and one digit.',
  'Request an Appointment': 'Request an Appointment',
  'Appointments can be made Monday to Friday from 9 a.m. to 1 p.m. and 2 p.m. to 6 p.m. or on Saturday from 9 a.m. to 1 p.m. Reservations are compulsory.': 'Appointments can be made Monday to Friday from 9 a.m. to 1 p.m. and 2 p.m. to 6 p.m. or on Saturday from 9 a.m. to 1 p.m. Reservations are compulsory.',
  'Murano Glass Chandelier': 'Murano Glass Chandelier',
  'Murano Glass Pendant Light': 'Murano Glass Pendant Light',
  'Murano Glass Sconce': 'Murano Glass Sconce',
  'Murano Glass Table Lamp': 'Murano Glass Table Lamp',
  'Murano Glass Floor lamp': 'Murano Glass Floor lamp',
  'Murano Glass Lantern': 'Murano Glass Lantern',
  'Murano Glass Ceiling Lamp': 'Murano Glass Ceiling Lamp',
  'Venetian Murano Mirror': 'Venetian Murano Mirror',
  'Murano Outdoor Glass Lamp': 'Murano Outdoor Glass Lamp',
  'Sogni Di Cristallo® - Artisans Of Light': 'Sogni Di Cristallo® - Artisans Of Light',
  'Murano Glass Factory in Venice - Sogni Di Cristallo®': 'Murano Glass Factory in Venice - Sogni Di Cristallo®',
  'Bespoke Glass Design & Ligthting Made In Italy - Sogni di Cristallo®': 'Bespoke Glass Design & Ligthting Made In Italy - Sogni di Cristallo®',
  'About Us - Sogni Di Cristallo®': 'About Us - Sogni Di Cristallo®',
  'Link copied.': 'Link copied.',
  'An error occurred while sharing the content.': 'An error occurred while sharing the content.',
  'An error occurred while copying the link.': 'An error occurred while copying the link.',
};
